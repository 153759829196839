<template>
  <div>
    <div v-show="!url">
      <VueFileAgent
        ref="vueFileAgent"
        :theme="theme"
        :multiple="multiple"
        :deletable="deletable"
        :sortable="sortable"
        :meta="meta"
        :accept="accept"
        :maxSize="maxSize"
        :maxFiles="maxFiles"
        :helpText="text"
        :errorText="{
          type: 'Formato do arquivo não permitido.',
          size: 'Os arquivos não podem exceder o tamanho de ' + maxSize,
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        @upload="onUpload($event)"
        @upload:error="onUploadError($event)"
        v-model="fileRecords"
      ></VueFileAgent>

      <a-button
        v-show="fileRecordsForUpload.length > 0"
        class="mt-10"
        type="primary"
        :disabled="!fileRecordsForUpload.length"
        @click="uploadFiles()"
      >
        <!-- Upload {{ fileRecordsForUpload.length }} files -->
        Carregar imagem
      </a-button>
    </div>
    <div v-show="url" class="avatar-preview">
      <a-avatar shape="square" :size="84" :src="url" />
      <a-button
        shape="circle"
        type="danger"
        icon="delete"
        @click="removeURL()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    multiple: Boolean,
    deletable: Boolean,
    sortable: Boolean,
    meta: Boolean,
    text: String,
    theme: String,
    maxSize: String,
    accept: String,
    maxFiles: Number,
    folder: String,
    userID: Boolean,
    userFolder: Boolean,
    url: String,
    id: Number,
  },
  data() {
    return {
      fileRecords: [],
      uploadUrl: ``,
      uploadHeaders: {
        authorization: `Bearer ${this.$store.state.token}`,
      },
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  methods: {
    uploadFiles() {
      let params = `&user_id=${this.$store.state.userData.id}`;
      this.userFolder ? (params += `&user_folder=true`) : ``;
      this.uploadUrl = `https://api.viajar.tur.br/v1/media/upload.php?folder=${this.folder}`;

      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl + params,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    removeURL() {
      this.$emit("fileURL", "", this.id);
      this.fileRecords = [];
    },
    onUpload(responses) {
      console.log("RESPONSE success", responses);

      // {\"message\":\"Imagem cards.png carregada com sucesso!\",\"status\":true,\"id\":\"18\",\"url\":\"https:\\/\\/api.viajar.tur.br\\/v1\\/uploads\\/public\\/settings\\/airlines\\/1\\/cards.png\"}
      responses.forEach((success) => {
        console.log(JSON.parse(success.request.response).message);

        this.$message.success(JSON.parse(success.request.response).message);

        this.$emit(
          "fileURL",
          JSON.parse(success.request.response).url,
          this.id
        );

        // console.log(error.response.data.message);
        //this.$message.error(error.response.data.message);
      });
    },
    onUploadError(failedResponses) {
      // handle error
      console.log("RESPONSE err", failedResponses);

      failedResponses.forEach((error) => {
        // console.log(error.response.data.message);
        this.$message.error(error.response.data.message);
      });
    },
    deleteUploadedFile(fileRecord) {
      this.uploadUrl = `https://api.viajar.tur.br/v1/media/upload.php?folder=${this.folder}`;
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>
